import IContact from "@/interfaces/models/contact.interface";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import { IDepartment } from '@/interfaces/models/IDepartment';

export enum MessageStatus {
  FAILURE = 'Failure',
  SUCCESS = 'Success',
  PENDING = 'Pending'
}

export default interface IMessage {
  id: number;
  agentAccount: IAgentAccount | null;
  contact: IContact | null;
  conversationId: number;
  createdAt: string;
  department: IDepartment;
  isInternalNote: boolean;
  isTextoBot: boolean;
  media: string | null;
  medias: string[] | [];
  status: MessageStatus;
  tag: string | null;
  tagColor: string | null;
  text: string | '';
  texts?: string[] | [];
  toNumber?: string | "";
}
