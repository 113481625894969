














































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IContact from "@/interfaces/models/contact.interface";
import {Validate} from "vuelidate-property-decorators";
import {minLength} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import IConversation from "@/interfaces/models/conversation.interface";
import store from "@/store";
import {Helpers} from "@/common/utils/helpers.js";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class AddContactPopup extends Vue {
  @Getter('contacts') contacts!: IContact[];
  @Getter('currentConversation') currentConversation!: IConversation;

  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Array, default: null}) selected!: string[];

  @Validate({minLength: minLength(1)})
  selectedContacts: any[] = [];

  search: string = '';
  availableContacts: IContact[] = [];
  isLoading: boolean = false;

  @Watch('showDialog', {immediate: true})
  showDialogWatcher() {
    if (this.showDialog) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  @Emit('close-dialog')
  closeDialog(): void {
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeDialog();
    }
  }

  async mounted(): Promise<void> {
    if (!this.contacts.length) {
      await this.$store.dispatch(actionTypes.FETCH_CONTACTS);
    }

    const participants = this.currentConversation.contacts.map(({id}: IContact) => id);
    this.availableContacts = this.contacts.filter(({id}: IContact) => !participants.includes(id));
  }

  formatNumber(number: string): string {
    return Helpers.formatNumber(number);
  }

  getInitials(contact: IContact): string {
    return Helpers.getInitials(contact);
  }

  observeSelected(): void {
    this.search = '';
    this.selectedContacts = this.selectedContacts.map((contact: any) => {
      if (typeof contact === 'string') {
        return {phoneNumber: contact}
      }
      return contact;
    }).filter(({phoneNumber}: any) => phoneNumber.replace(/\D+/g, ''));
  }

  async addParticipants(): Promise<void> {
    (this.$refs.addField as any).blur();
    await this.$nextTick();
    this.$v.$touch();
    const ids: number[] = [], phoneNumbers: string[] = [];
    this.selectedContacts.forEach(({phoneNumber}: { phoneNumber: string }) => {
      const existedContact = this.contacts.find((contact: IContact) => contact.phoneNumber === phoneNumber);

      if (existedContact?.id) {
        ids.push(existedContact.id);
      } else {
        const normalizedNumber = phoneNumber.startsWith('+1')
          ? phoneNumber
          : phoneNumber.startsWith('1') ? `+${phoneNumber}` : `+1${phoneNumber}`;
        phoneNumbers.push(normalizedNumber);
      }
    });

    try {
      this.isLoading = true;

      await Promise.all(ids.map(id => this.$store.dispatch(actionTypes.ADD_PARTICIPANT, {
        contactId: id,
      })));

      await Promise.all(phoneNumbers.map(number => this.$store.dispatch(actionTypes.ADD_PARTICIPANT, {
        phoneNumber: number,
      })));

      await this.$store.dispatch(actionTypes.FETCH_CURRENT_CONVERSATION, this.currentConversation.id);
      await this.$store.dispatch(actionTypes.FETCH_MESSAGES, this.currentConversation.id);

      this.closeDialog();
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  contactsFilter(contact: IContact, value: string): boolean {
    return contact.firstName?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      || contact.lastName?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      || contact.phoneNumber.toLocaleLowerCase().includes(value.toLocaleLowerCase());
  }
}
