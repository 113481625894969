








































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from "@/store/action-types";
import moment from "moment";
import { Helpers } from "@/common/utils/helpers.js";
import { Getter } from "vuex-class";
import { NotificationType } from "@/interfaces/models/INotification";

@Component
export default class ConversationPreview extends Vue {
  @Getter currentConversation!: IConversation;
  @Prop(Object) conversation!: IConversation;
  @Prop(Number) selectedConversationId!: number;
  editNameMode: boolean = false;
  isChecked: boolean = false;

  @Emit("select-user")
  selectUser(): number {
    return this.conversation.id;
  }

  @Watch("conversation", { immediate: true })
  onConversationChange(): void {
    this.isChecked = !this.conversation.isOpen;
  }

  get contactName(): string {
    const name = `${this.conversation.contacts[0].firstName} ${this.conversation.contacts[0].lastName}`;
    return Helpers.truncateText(name, 16);
  }

  get timeSinceMessage(): string {
    return moment(this.conversation.latestMessage?.createdAt).fromNow();
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  handleMouseEnter(): void {
    if (this.isMobile) {
      return;
    }

    this.isChecked = true;
  }

  handleMouseLeave(): void {
    if (this.conversation.isOpen && !this.isMobile) {
      this.isChecked = false;
    }
  }

  enableEditMode(event: Event): void {
    event.stopPropagation();
    this.editNameMode = true;

    this.$nextTick(() => {
      (this.$refs.int as HTMLInputElement).focus();
    });
  }

  async closeConversation(): Promise<void> {
    try {
      const isOpen = !this.conversation.isOpen;
      const currentConversationId = this.currentConversation?.id;

      await this.$store.dispatch(actionTypes.TOGGLE_CONVERSATION, {
        conversationId: this.conversation.id,
        isOpen
      });

      if (!isOpen && currentConversationId === this.conversation.id) {
        await this.$router.replace("/inbox");
      }
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async updateName(): Promise<void> {
    this.editNameMode = false;
    const newName = (this.$refs.int as HTMLInputElement).value;

    if (newName?.length && newName !== this.conversation.name) {
      await this.$store.dispatch(actionTypes.UPDATE_CONVERSATION_NAME, {
        conversation: this.conversation,
        name: newName
      });
    }
  }

  get latestMessagePhoto(): string | boolean {
    const latestMessage = this.conversation.latestMessage;
    return Helpers.getProfilePhoto(latestMessage!.agentAccount || latestMessage!.contact);
  }

  get conversationName(): string {
    return Helpers.truncateText(this.conversation.name, 22);
  }

  get message(): string {
    if (this.conversation.latestMessage?.media) {
      return "Attachment";
    }

    return Helpers.truncateText(this.conversation.latestMessage?.text, 50);
  }

  getInitials(): string {
    return Helpers.getInitials(
      this.conversation.latestMessage?.agentAccount || this.conversation.latestMessage?.contact
    );
  }
}
