





































































































import { Component, Emit, Vue } from "vue-property-decorator";
import IContact from "@/interfaces/models/contact.interface";
import AddContactPopup from "@/components/inbox/AddContactPopup.vue";
import ConfirmModal from "@/components/admin-settings/ConfirmModal.vue";
import { Getter } from "vuex-class";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from "@/store/action-types";
import { Helpers } from "@/common/utils/helpers.js";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import ConversationAssigneeAgent from "@/components/inbox/ConversationAssigneeAgent.vue";
import { NotificationType } from "@/interfaces/models/INotification";
import ConversationAssigneeDepartment from "@/components/inbox/ConversationAssigneeDepartment.vue";

@Component({
  components: {
    ConfirmModal,
    ConversationAssigneeDepartment,
    ConversationAssigneeAgent,
    AddContactPopup
  }
})
export default class ChatMembers extends Vue {
  @Getter currentConversation!: IConversation;
  @Getter agent!: IAgentAccount;
  @Getter isMessagesFetched!: Boolean;
  showAddPopup: boolean = false;
  contactToPrecess: IContact | null = null;

  contactsInLoad: number[] = [];
  popupConfig = {
    btnText: "",
    btnColor: "",
    title: "",
    label: "",
    action: "",
    isShowPopup: false
  };

  @Emit("close-conversation")
  async closeConversation(): Promise<void> {
    await this.$store.dispatch(actionTypes.TOGGLE_CONVERSATION, {
      conversationId: this.currentConversation.id,
      isOpen: !this.currentConversation.isOpen
    });

    if (!this.currentConversation?.isOpen) {
      await this.$router.replace("/inbox");
    } else {
      this.$emit("change-tab", 0);
    }
  }

  get assignee(): string {
    if (this.currentConversation.agentAccount?.id === this.agent.id) {
      return `${this.agent.firstName} ${this.agent.lastName}`;
    } else if (this.currentConversation.agentAccount) {
      return `${this.currentConversation.agentAccount.firstName} ${this.currentConversation.agentAccount.lastName}`;
    } else {
      return "";
    }
  }

  get countOfActiveMembers(): number {
    return this.currentConversation.contacts.reduce((acc, { isActive }) => acc + +isActive, 0);
  }

  closeAddContactPopup() {
    this.showAddPopup = false;
  }

  deactivateContact(contact: IContact): void {
    this.contactToPrecess = contact;
    this.popupConfig = {
      ...this.popupConfig,
      label: `You are about to deactivate ${contact.firstName} ${contact.lastName} from this conversation. Are you sure you want to do this?`,
      btnColor: "#FE4D97",
      btnText: "Deactivate",
      title: "Remove Participant",
      action: "Remove",
      isShowPopup: true
    };
  }

  activateContact(contact: IContact): void {
    this.contactToPrecess = contact;
    this.popupConfig = {
      ...this.popupConfig,
      label: `You are about to activate ${contact.firstName} ${contact.lastName} from this conversation. Are you sure you want to do this?`,
      btnColor: "#4D7CFE",
      btnText: "Activate",
      title: "Activate participant",
      action: "Activate",
      isShowPopup: true
    };
  }

  processConfirming() {
    const actionName =
      this.popupConfig.action === "Activate"
        ? actionTypes.ACTIVATE_CONTACT
        : actionTypes.DEACTIVATE_CONTACT;
    this.changeContactState(actionName, this.contactToPrecess!.id);
  }

  async changeContactState(actionName: string, contactId: number): Promise<void> {
    try {
      this.popupConfig.isShowPopup = false;
      this.contactsInLoad = this.contactsInLoad.concat(contactId);
      await this.$store.dispatch(actionName, contactId);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.contactsInLoad = this.contactsInLoad.filter(id => id !== contactId);
    }
  }

  isShowLoader({ id }: IContact): boolean {
    return this.contactsInLoad.includes(id);
  }

  formatNumber(number: string): string {
    return Helpers.formatNumber(number);
  }

  isCanActivate(contact: IContact): boolean {
    return !contact.isActive && !this.isShowLoader(contact) && this.currentConversation.isOpen;
  }

  isCanDeactivate(contact: IContact): boolean {
    return (
      contact.isActive &&
      !this.isShowLoader(contact) &&
      this.currentConversation.isOpen &&
      this.countOfActiveMembers !== 1
    );
  }
}
