


























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import IContact from "@/interfaces/models/contact.interface";
import IConversation from "@/interfaces/models/conversation.interface";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import { Helpers } from "@/common/utils/helpers.js";
import router from "@/router";
import Pusher from "pusher-js";
import { ENV } from "@/common/utils/env";
import IConversationAutomation from "@/interfaces/models/IConversationAutomation";

@Component
export default class UserInfoOverview extends Vue {
  @Getter currentConversation!: IConversation;
  @Getter currentContact!: IContact;
  automations: Array<IConversationAutomation> = [];

  @Watch("currentConversation")
  updateCurrentContact(): void {
    if (this.currentConversation) {
      this.automations = this.currentConversation.automations;
      if (this.currentConversation?.contacts.length) {
        this.$store.commit(mutationTypes.SET_CURRENT_CONTACT, this.currentConversation.contacts[0]);
      }
    }
  }

  get phoneNumber(): string {
    return Helpers.formatNumber(this.currentContact.phoneNumber);
  }

  openEditDrawer(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, {
      contactId: this.currentContact.id,
      insideConversation: true
    });
  }

  truncateText(text: string): string {
    return Helpers.truncateText(text, 20);
  }

  /**
   * Automations
   */
  loading: boolean = false;
  activeAutomation: number | null = null;
  toggleAutomation(id: number): void {
    this.activeAutomation = this.activeAutomation === id ? null : id;
  }
  viewAutomation(id: number): void {
    router.push(`/settings/automations/${id}`);
  }
  viewAutomationStep(automationId: number, stepId: number): void {
    router.push(`/settings/automations/${automationId}?stepId=${stepId}`);
  }

  showDialog: boolean = false;
  automationToDelete = {
    id: 0,
    name: ""
  };

  async deleteAutomation(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch(
        actionTypes.REMOVE_CONVERSATION_AUTOMATION,
        this.automationToDelete.id
      );
      this.automations = this.automations.filter(
        ({ automationId }) => automationId !== this.automationToDelete.id
      );
      this.activeAutomation = null;
    } catch (error) {
      // Handle any error that occurred during the dispatch
      console.error(error);
    }
    this.showDialog = false;
    this.loading = false;
  }

  pusher: any;
  channel: any;
  initPusher(): void {
    this.pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: "us2"
    });
    this.channel = this.pusher.subscribe("textodog-automation");
    this.channel.bind("conversationAutomationUpdated", (automation: any) => {
      if (automation.conversationId === +this.$route.params.id) {
        delete automation.conversationId;
        const indexOfAutomation = this.automations.findIndex(
          ({ automationId }) => automationId === automation.automationId
        );
        if (indexOfAutomation !== -1) {
          this.automations.splice(indexOfAutomation, 1, automation);
        } else {
          this.automations.push(automation);
        }
      }
    });
    this.channel.bind("conversationAutomationRemoved", (automation: any) => {
      if (automation.conversationId === +this.$route.params.id) {
        this.automations = this.automations.filter(
          ({ automationId }) => automationId !== automation.automationId
        );
      }
    });
  }

  mounted() {
    this.initPusher();
  }

  beforeUnmount(): void {
    this.pusher.unsubscribe("textodog-automation");
  }
}
