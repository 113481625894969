










































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from '@/store/action-types';
import {NotificationType} from "@/interfaces/models/INotification";
import { IDepartment } from '@/interfaces/models/IDepartment';

@Component({
  name: 'ConversationAssigneeAgent'
})
export default class ConversationAssigneeAgent extends Vue {
  @Getter agent!: IAgentAccount;
  @Getter currentConversation!: IConversation;
  @Getter departments!: IDepartment[];

  isOpened: boolean = false;
  isLoading: boolean = false;

  get currentAssignee(): IDepartment | boolean {
    if (this.currentConversation.department) {
      return this.departments.find(({id}: IDepartment) => this.currentConversation.department.id === id)!;
    }
    return false;
  }

  async mounted(): Promise<void> {
    if (!this.departments.length) {
      await this.loadDepartments();
    }
  }

  async loadDepartments(): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.FETCH_DEPARTMENTS);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async assignDepartment(department: IDepartment): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.CHANGE_ASSIGNEE_DEPARTMENT, department.id);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
