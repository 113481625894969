var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"dialog",attrs:{"no-click-animation":"","width":"750","value":_vm.showDialog,"fullscreen":_vm.$vuetify.breakpoint.xs},on:{"click:outside":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"px-5 py-3 d-flex align-center"},[_c('div',{staticClass:"d-none d-sm-flex align-center justify-space-between desktop-header"},[_c('span',[_vm._v("Add Participants")]),_c('Icon',{attrs:{"iconName":"close"},on:{"click":_vm.closeDialog}})],1),_c('div',{staticClass:"mobile-header d-flex d-sm-none justify-space-between align-center"},[_c('div',{staticClass:"left-side"},[_c('v-icon',{staticClass:"mr-3",on:{"click":_vm.closeDialog}},[_vm._v("mdi-chevron-left")]),_vm._v(" Manage Conversation ")],1),_c('v-icon',{staticClass:"d-block",on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"content px-4 py-sm-6"},[_c('div',{staticClass:"display-name"},[_c('label',{staticClass:"label d-none d-sm-block"},[_vm._v("Participants*")]),_c('v-combobox',{ref:"addField",staticClass:"mt-3",attrs:{"multiple":"","outlined":"","autofocus":"","clearable":"","small-chips":"","single-line":"","hide-details":"","hide-selected":"","deletable-chips":"","item-value":"id","background-color":"white","label":"Enter phone number or contact name","append-icon":null,"filter":_vm.contactsFilter,"items":_vm.availableContacts,"search-input":_vm.search,"menu-props":{closeOnContentClick: true},"hide-no-data":!_vm.search || !_vm.search.trim(),"error-messages":_vm.$v.selectedContacts.$dirty && !_vm.selectedContacts.length ? 'Select contact' : null},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.observeSelected},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
var index = ref.index;
return [_c('v-chip',_vm._b({staticClass:"chip",attrs:{"input-value":selected,"label":"","small":"","close-icon":"mdi-close","close":index === _vm.selectedContacts.length - 1},on:{"click:close":function($event){return _vm.selectedContacts.splice(index, 1)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.phoneNumber))+" ")])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"label":""}},[_c('contact-avatar',{staticClass:"mr-3",attrs:{"contact":item,"size":24}}),_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ("+_vm._s(item.phoneNumber)+") ")],1)]}}]),model:{value:(_vm.selectedContacts),callback:function ($$v) {_vm.selectedContacts=$$v},expression:"selectedContacts"}})],1)]),_c('v-card-actions',{staticClass:"pa-5 d-flex align-center"},[_c('v-btn',{staticClass:"px-10",attrs:{"large":"","depressed":"","color":"#4D7CFE","dark":"","block":_vm.$vuetify.breakpoint.xs,"loading":_vm.isLoading},on:{"click":_vm.addParticipants}},[_vm._v(" Add ")]),_c('v-spacer'),_c('v-btn',{staticClass:"px-10 d-none d-sm-block",attrs:{"depressed":"","large":"","color":"#F2F4F6"},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }