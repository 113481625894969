








































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from "@/store/action-types";
import GiphyPopup from "@/components/inbox/GiphyPopup.vue";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import { Helpers } from "@/common/utils/helpers.js";
import { NotificationType } from "@/interfaces/models/INotification";
import SavedRepliesPopup from "@/components/inbox/SavedRepliesPopup.vue";
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import imageLoadingHelper from "@/common/utils/image-loading-helper";
import EmojiPicker from "../emoji-picker/EmojiPicker.vue";

@Component({
  components: { SavedRepliesPopup, GiphyPopup, EmojiPicker }
})
export default class MessageTextArea extends Vue {
  @Getter currentConversation!: IConversation;
  @Getter agent!: IAgentAccount;
  @Getter isMessagesFetched!: Boolean;

  currentTabIndex: number = 0;
  messageContent: string = "";
  isSending: boolean = false;
  mediaFile: string = "";
  imageName: string = "";
  loading: boolean = false;

  mounted(): void {}

  @Watch("currentTabIndex")
  onChangeTab(): void {
    this.messageContent = "";
  }

  get conversationName(): string {
    return this.currentConversation ? Helpers.truncateText(this.currentConversation.name, 20) : "";
  }

  get isConversationOpen(): boolean {
    return this.currentConversation ? this.currentConversation.isOpen : false;
  }

  closeConversation(): void {
    this.$store.dispatch(actionTypes.TOGGLE_CONVERSATION, false);
  }

  handleReplySelection({ content }: ISavedReply): void {
    this.messageContent += content;
  }

  async findURL(): Promise<void> {
    this.isSending = true;
    const res: any = await this.changeUrlFromText();
    this.isSending = false;
    let replacedText = this.messageContent;

    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = replacedText.replace(url1, shortUrl);
      });
      this.messageContent = replacedText;
    }
  }

  async changeUrlFromText(): Promise<void> {
    return await Helpers.urlify(this.messageContent);
  }

  async updateMessage(): Promise<void> {
    this.startTypingEvent();
  }
  startTypingEvent() {
    const param: any = {
      conversationId: +this.$route.params.id,
      message: this.messageContent,
      is_note: !!this.currentTabIndex
    };
    this.$emit("typing-event", param);
  }

  async sendMessage(): Promise<void> {
    if (this.isSending || (!this.messageContent.trim() && !this.mediaFile)) {
      return;
    }
    this.isSending = true;

    const res: any = await this.changeUrlFromText();
    let replacedText = this.messageContent;
    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = replacedText.replace(url1, shortUrl);
      });
    }
    try {
      const message = {
        conversationId: +this.$route.params.id,
        isInternalNote: !!this.currentTabIndex,
        text: replacedText.trim() || null,
        agentAccountId: this.agent.id,
        media: this.mediaFile
      };

      await this.$store.dispatch(actionTypes.SEND_MESSAGE, message);
      this.messageContent = this.mediaFile = "";
      this.startTypingEvent();
      this.$emit("new-message");
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isSending = false;
    }
  }

  handleEmojiSelect(emoji: string): void {
    const lastCursorPosition = document.querySelector("textarea")?.selectionStart || 0;
    this.messageContent =
      this.messageContent.substr(0, lastCursorPosition) +
      emoji +
      this.messageContent.substr(lastCursorPosition);

    // outside click to close emoji picker
    document.body.click();
  }

  async handleGIFSelection({ url, title }: { url: string; title: string }): Promise<void> {
    this.mediaFile = url.split("?cid")[0];
    this.imageName = title.split("GIF")[0].trim();
  }

  loadImage(): void {
    (this.$refs.imageInput as HTMLInputElement).click();
  }

  async handleImageSelection(event: any): Promise<void> {
    try {
      this.imageName = event.target.files[0].name.split(".")[0];
      this.mediaFile = await imageLoadingHelper(event);
      (this.$refs.imageInput as HTMLInputElement).value = "";
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  get textAreaColor(): string {
    return this.currentTabIndex === 0 ? "#fff" : "#FFF6D9";
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }
}
