


























































import { Component, Vue, Watch } from "vue-property-decorator";
import ConversationPreview from "@/components/inbox/ConversationPreview.vue";
import MessagesList from "@/components/inbox/MessagesList.vue";
import ConversationList from "@/components/inbox/ConversationList.vue";
import UserInfoOverview from "@/components/inbox/UserInfoOverview.vue";
import EditContactDrawer from "@/components/contacts/EditContactDrawer.vue";
import ComposeMessagePopup from "@/components/inbox/ComposeMessagePopup.vue";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { Getter } from "vuex-class";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import IContact from "@/interfaces/models/contact.interface";
import Pusher from "pusher-js";
import { ENV } from "@/common/utils/env";
import { MessageService } from "@/http/services/message.service";

@Component({
  components: {
    ComposeMessagePopup,
    EditContactDrawer,
    UserInfoOverview,
    ConversationList,
    MessagesList,
    ConversationPreview
  }
})
export default class Inbox extends Vue {
  @Getter agent!: IAgentAccount;
  @Getter contacts!: IContact[];

  selectedConversation = "";
  showCompose: boolean = false;
  isLoaded: boolean = false;
  pusher: any;
  channel: any;

  @Watch("$route.params", { immediate: true })
  onPathParamChange() {
    this.selectedConversation = this.$route.params.id;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  async mounted() {
    this.initPusher();
    this.isLoaded = true;
  }

  initPusher(): void {
    this.pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: "us2"
    });
    this.channel = this.pusher.subscribe("textodog-message");
    this.channel.bind("conversationCreated", (data: any) => {
      if (data) {
        this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
      }
    });
    this.channel.bind("messageStatusUpdated", async (data: any) => {
      if (this.$route.params.id) {
        await MessageService.fetchMessages(Number(this.$route.params.id)).then(messages => {
          if (
            data.status == "success" &&
            this.$route.params.id == data.conversationId &&
            messages[0].agentAccount?.id != this.$store.getters.agent.id
          ) {
            this.$store.commit(mutationTypes.SET_MESSAGES, messages.reverse());
            this.$store.commit(mutationTypes.UPDATE_LATEST_MESSAGE, messages[messages.length - 1]);
          }
        });
      }
    });
    this.channel.bind(
      "conversationStatusUpdated",
      (data: { conversationId: number; isOpen: boolean }) => {
        // give time to update the conversation list if we TOGGLE_CONVERSATION ourselves, not if someone else toggles it
        // then we can avoid calling TOGGLE_CONVERSATION twice
        setTimeout(() => {
          this.$store.dispatch(actionTypes.TOGGLE_CONVERSATION, {
            conversationId: data.conversationId,
            isOpen: data.isOpen
          });
        }, 300);
      }
    );
  }

  changeConversationListTab(tabIdx: number): void {
    if (this.$refs.conversationList) {
      (this.$refs.conversationList as ConversationList).currentTabIndex = tabIdx;
    }
  }

  onClickPlus(): void {
    if (this.contacts.length > 0) this.showCompose = true;
    else {
      this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
    }
  }
}
