














































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import IMessage, { MessageStatus } from "@/interfaces/models/message.interface";
import moment from "moment";
import * as mutationTypes from "@/store/mutation-types";
import { Helpers } from "@/common/utils/helpers.js";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import IContact from "@/interfaces/models/contact.interface";

@Component
export default class MessageBubble extends Vue {
  @Prop({ type: Object, required: true }) message!: IMessage;

  @Emit("loaded")
  imgLoaded(): void {}

  get isMessageFromContact(): boolean {
    return !!this.message.contact;
  }

  get sender(): IAgentAccount | IContact | null {
    return this.message?.agentAccount || this.message?.contact || null;
  }

  get isFailure(): boolean {
    return this.message.status === MessageStatus.FAILURE;
  }

  get hasTag(): boolean {
    return !!this.message.tag && !!this.message.tagColor;
  }

  get messageTime(): string {
    if (!this.sender) {
      return "";
    }

    const time = moment(this.message.createdAt).format("LLL");

    if (!this.isMessageFromContact) {
      return `${time}<br/>${this.sender.firstName || ""} ${this.sender.lastName || ""}`;
    }

    const senderName = `${this.sender.firstName || ""} ${this.sender.lastName || ""}`;
    return `${time}<br/>${senderName} ${Helpers.formatNumber(this.sender?.phoneNumber)}`;
  }

  selectContact(): void {
    if (!this.isMessageFromContact) {
      return;
    }

    this.$store.commit(mutationTypes.SET_CURRENT_CONTACT, this.message.contact);
  }

  urlify(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const agentMessage = !this.message.isInternalNote && !this.isMessageFromContact;
    return text.replace(urlRegex, function(url) {
      return `<a href="${url}" target="_blank" style="color: ${agentMessage && "white"}">${url}</a>`;
    });
  }
}
