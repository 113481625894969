













































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { IDepartment } from "@/interfaces/models/IDepartment";

@Component({
  name: "ConversationAssigneeAgent"
})
export default class ConversationAssigneeAgent extends Vue {
  @Getter agent!: IAgentAccount;
  @Getter currentConversation!: IConversation;
  @Getter agents!: IAgentAccount[];

  isOpened: boolean = false;
  isLoading: boolean = false;

  get availableAgents(): IAgentAccount[] {
    return this.agents.filter(({ id }: IAgentAccount) => id !== this.agent?.id);
  }

  get currentAssignee(): IAgentAccount | boolean {
    if (this.currentConversation.agentAccount) {
      return this.agents.find(
        ({ id }: IAgentAccount) => this.currentConversation.agentAccount.id === id
      )!;
    }
    return false;
  }

  async mounted(): Promise<void> {
    if (!this.agents.length) {
      await this.loadAgents();
    }
  }

  async loadAgents(): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.FETCH_AGENTS);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async assignAgent(agent: IAgentAccount): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.CHANGE_ASSIGNEE_AGENT, agent.id);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
