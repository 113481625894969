






















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ConversationPreview from "@/components/inbox/ConversationPreview.vue";
import IConversation from "@/interfaces/models/conversation.interface";
import * as actionTypes from "@/store/action-types";

@Component({
  components: { ConversationPreview }
})
export default class ConversationList extends Vue {
  @Getter("openedConversations") openedConversations!: IConversation[];
  @Getter("closedConversations") closedConversations!: IConversation[];
  @Getter currentConversation!: IConversation;

  selectedConversationId: number = 0;
  currentTabIndex: number = 0;
  searchValueOpened: string = "";
  searchValueClosed: string = "";
  conversationSearchDebounceTimer: number = 0;
  isLoading: boolean = true;
  isLoadingSearch: boolean = true;
  curPage: number = 1;
  sameClosed: boolean = false;
  sameOpened: boolean = false;

  @Watch("$route.params", { immediate: true })
  onPathParamChange() {
    this.selectedConversationId = +this.$route.params.id;
  }

  @Watch("currentConversation")
  openCorrectTab(): void {
    if (this.currentConversation) {
      this.currentTabIndex = +!this.currentConversation.isOpen;
    }
  }

  @Watch("currentTabIndex")
  async onTabChange(): Promise<void> {
    if (this.currentTabIndex && !this.closedConversations.length) {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, {
        isOpen: false,
        currentPage: 1
      });
      this.isLoading = false;
    }
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get sortedOpenedConversations(): IConversation[] {
    this.isLoading = false;
    this.isLoadingSearch = false;
    return this.openedConversations;
    // .filter(c => c.name.toLowerCase().includes(this.searchValueOpened.toLowerCase()))
    // .sort((a, b) => moment(b.latestMessage?.createdAt).diff(moment(a.latestMessage?.createdAt)));
  }

  get sortedClosedConversations(): IConversation[] {
    this.isLoading = false;
    this.isLoadingSearch = false;
    return this.closedConversations;
    // .filter(c => c.name.toLowerCase().includes(this.searchValueClosed.toLowerCase()))
    // .sort((a, b) => moment(b.latestMessage?.createdAt).diff(moment(a.latestMessage?.createdAt)));
  }

  async mounted() {
    await this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });

    if (!this.sortedOpenedConversations.length || !this.sortedClosedConversations.length) {
      this.isLoading = false;
    }
    if (this.$vuetify.breakpoint.smAndUp && this.sortedOpenedConversations.length) {
      this.openLastDialog();
    }
  }

  openedScrollDetection() {
    // Detect when scrolled to the bottom.
    const openedConversationsList = document.querySelector("#opened-list");

    if (openedConversationsList) {
      openedConversationsList.addEventListener("scroll", async () => {
        const _ = require("lodash");
        const openedConversationsToCompare = this.openedConversations;
        if (
          openedConversationsList.scrollTop + openedConversationsList.clientHeight >=
            openedConversationsList.scrollHeight &&
          !this.sameOpened
        ) {
          this.isLoadingSearch = true;
          await this.$store.dispatch(actionTypes.FETCH_MORE_CONVERSATIONS, {
            isOpen: true,
            name: this.searchValueOpened,
            currentPage: ++this.curPage
          });
          _.isEqual(this.openedConversations, openedConversationsToCompare)
            ? (this.sameOpened = true)
            : (this.sameOpened = false);
        }
      });
    }
  }

  closedScrollDetection() {
    // Detect when scrolled to the bottom.
    const closedConversationsList = document.querySelector("#closed-list");

    if (closedConversationsList) {
      closedConversationsList.addEventListener("scroll", async () => {
        const _ = require("lodash");
        const closedConversationsToCompare = this.closedConversations;
        if (
          closedConversationsList.scrollTop + closedConversationsList.clientHeight >=
            closedConversationsList.scrollHeight &&
          !this.sameClosed
        ) {
          this.isLoadingSearch = true;
          await this.$store.dispatch(actionTypes.FETCH_MORE_CONVERSATIONS, {
            isOpen: false,
            name: this.searchValueClosed,
            currentPage: ++this.curPage
          });
          _.isEqual(this.closedConversations, closedConversationsToCompare)
            ? (this.sameClosed = true)
            : (this.sameClosed = false);
        }
      });
    }
  }

  async searchOpenedConversation(): Promise<void> {
    clearTimeout(this.conversationSearchDebounceTimer);

    this.conversationSearchDebounceTimer = setTimeout(async () => {
      this.isLoadingSearch = true;
      await this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, {
        isOpen: true,
        name: this.searchValueOpened
      });
      this.curPage = 1;
      this.sameOpened = false;
      this.isLoadingSearch = false;
    }, 1000);
  }

  async searchClosedConversation(): Promise<void> {
    clearTimeout(this.conversationSearchDebounceTimer);

    this.conversationSearchDebounceTimer = setTimeout(async () => {
      this.isLoadingSearch = true;
      await this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, {
        isOpen: false,
        name: this.searchValueClosed
      });
      this.curPage = 1;
      this.sameClosed = false;
      this.isLoadingSearch = false;
    }, 1000);
  }

  selectUser(userId: string): void {
    this.searchValueOpened = "";
    this.searchValueClosed = "";
    this.curPage = 1;
    this.sameOpened = false;
    this.sameClosed = false;
    this.$store.dispatch(actionTypes.FETCH_CURRENT_CONVERSATION, userId);

    const newPath = `/inbox/${userId}`;
    if (this.$route.path !== newPath) {
      this.$router.push({
        path: newPath
      });
    }
  }

  openLastDialog(): void {
    if (this.selectedConversationId) {
      return;
    }

    this.$router.push(`/inbox/${this.sortedOpenedConversations[0].id}`).catch(() => {});
  }
}
