





















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import MessageBubble from "@/components/inbox/MessageBubble.vue";
import "simplebar-vue/dist/simplebar.min.css";
import simplebar from "simplebar-vue";
import MessageTextArea from "@/components/inbox/MessageTextArea.vue";
import EditContactDrawer from "@/components/contacts/EditContactDrawer.vue";
import ChatMembers from "@/components/inbox/ChatMembers.vue";
import IMessage from "@/interfaces/models/message.interface";
import * as actionTypes from "@/store/action-types";
import IConversation from "@/interfaces/models/conversation.interface";
import { Helpers } from "@/common/utils/helpers.js";
import IContact from "@/interfaces/models/contact.interface";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import Pusher from "pusher-js";
import { ENV } from "@/common/utils/env";
import { MessageService } from "@/http/services/message.service";

interface ITypingUser {
  agentAccountId: number;
  agentAccountFullName: string;
  agentAccountPhoto: string | null;
  conversationId: number;
  message: string;
  expired: number;
  is_note: boolean;
}

@Component({
  components: {
    ChatMembers,
    EditContactDrawer,
    MessageTextArea,
    MessageBubble,
    simplebar
  }
})
export default class MessagesList extends Vue {
  @Getter messages!: IMessage[];
  @Getter currentConversation!: IConversation;
  @Getter currentContact!: IContact;
  @Getter agent!: IAgentAccount;

  pusher: any;
  channel: any;
  isShowEditContact: boolean = false;
  isLoaded: boolean = false;
  customMessages: IMessage[] = [];
  room_id: number = 0;
  typingUsers: ITypingUser[] = [];
  sendTypingEvent: boolean = true;
  // typingUsers: ITypingUser[] = [
  //   {agentAccountId: 1, agentAccountFullName: 'Alain PILON', agentAccountPhoto: null, message: "test", conversationId: 14},
  //   {agentAccountId: 2, agentAccountFullName: 'Tlain PILON', agentAccountPhoto: null, message: "sdf", conversationId: 14}];

  @Watch("$route.params.id")
  async observeIdParam(): Promise<void> {
    if (this.$route.name === "Inbox selected") {
      this.isLoaded = false;
      await this.$store.dispatch(actionTypes.FETCH_MESSAGES, +this.$route.params.id);
      this.scrollToDummy();
    }
  }

  @Watch("messages")
  observeMessagesChange(): void {
    this.scrollToDummy();
    this.customMessages = [];
    let tempMessages: IMessage[] = [];
    if (this.messages.length > 0) {
      let texts: string[] = [];
      let medias: string[] = [];
      for (let index = 0; index < this.messages.length; index++) {
        const iMessage = this.messages[index];
        let toNumber1 = iMessage.createdAt;
        if (iMessage.text) texts.push(iMessage.text);
        if (iMessage.media) medias.push(iMessage.media);

        // confirm with next message
        // combine 2 messages into one if they have the same createdAt value ?
        // for (let j = index + 1; j < this.messages.length; j++) {
        //   const jMessage = this.messages[j];
        //   let toNumber2 = jMessage.createdAt;
        //   if (toNumber1 === toNumber2) {
        //     if (jMessage.text) texts.push(jMessage.text);
        //     if (jMessage.media) medias.push(jMessage.media);
        //     index++;
        //   } else {
        //     break;
        //   }
        // }
        // push new messages
        const newMessage: IMessage = {
          ...this.messages[index],
          ...{ texts, medias }
        };
        tempMessages.push(newMessage);
        texts = [];
        medias = [];
      }
    }
    this.customMessages = tempMessages;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  getAvatarName(fullName: string): string {
    if (fullName.length === 1) return fullName;
    if (fullName.length === 2) return fullName;
    var arr = fullName.split(" ");
    if (arr.length >= 2) return `${arr[0][0]}${arr[1][0]}`;
    else return fullName.slice(-2);
  }

  async mounted() {
    this.isLoaded = false;
    if (this.$route.name === "Inbox selected" && this.$route.params.id) {
      await Promise.all([
        this.$store.dispatch(actionTypes.FETCH_MESSAGES, +this.$route.params.id),
        this.$store.dispatch(actionTypes.FETCH_CURRENT_CONVERSATION, +this.$route.params.id)
      ]);
    }
    this.setMessageBoxHeight(window.innerHeight);
    this.scrollToDummy();

    setInterval(() => this.clearTypingEvent(), 1000);
    window.addEventListener("focus", this.loadMissedMessages);
  }
  beforeUnmount(): void {
    this.pusher.unsubscribe("private-auth-broadcast-" + this.room_id);
  }

  @Watch("currentConversation")
  getCurrentConversation(): void {
    if (this.currentConversation) {
      this.typingUsers = [];
      this.initPusher();
    }
  }

  initPusher(): void {
    let authorizer = (channel: any, options: any) => {
      return {
        authorize: async (
          socketId: string,
          callback: (arg0: Error | null, arg1: { auth: string; user_info: any }) => void
        ) => {
          try {
            const data: any = await MessageService.authPusher({
              socket_id: socketId,
              channel_name: channel.name
            });
            callback(null, data);
          } catch (error) {
            callback(new Error(`Error calling auth endpoint: ${error}`), {
              auth: "",
              user_info: {}
            });
          }
        }
      };
    };
    this.pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: "us2",
      authorizer: authorizer
    });

    if (this.room_id !== 0) {
      this.pusher.unsubscribe("private-auth-broadcast-" + this.room_id);
    }
    this.room_id = this.currentConversation.id;
    this.channel = this.pusher.subscribe("private-auth-broadcast-" + this.room_id);
    this.channel.bind("client-authBroadcast", (typingUser: any) => {
      if (typingUser) {
        if (
          this.room_id === typingUser.conversationId &&
          this.agent.id !== typingUser.agentAccountId
        ) {
          const matchIndex = this.typingUsers.findIndex(
            tu => tu.agentAccountId === typingUser.agentAccountId
          ); // to check if users are still typing
          const expired = Math.floor(Date.now() / 1000) + 600; // 10 mins
          if (matchIndex === -1) {
            this.typingUsers.push({ ...typingUser, ...{ expired } });
          } else {
            this.typingUsers[matchIndex].message = typingUser.message; // to update message and expired time for user who is still typing
            this.typingUsers[matchIndex].expired = expired;
          }
          this.typingUsers = this.typingUsers.filter((k: ITypingUser) => k.message !== "");
          this.scrollToDummy();
        }
      }
    });
  }

  async typingEvent(param: any) {
    if (this.channel && this.sendTypingEvent) {
      this.channel.trigger("client-authBroadcast", {
        ...param,
        ...{
          agentAccountId: this.agent.id,
          agentAccountFullName: this.agent.firstName + " " + this.agent.lastName,
          agentAccountPhoto: this.agent.photo
        }
      });
      this.sendTypingEvent = false;
      setTimeout(() => {
        this.sendTypingEvent = true;
      }, 2000);
    }
  }

  clearTypingEvent(): void {
    if (this.room_id && this.typingUsers.length > 0) {
      const now = Math.floor(Date.now() / 1000);
      let tempUsers: ITypingUser[] = [];
      for (let tpU of this.typingUsers) {
        if (now < tpU.expired) {
          tempUsers.push(tpU);
        }
      }
      this.typingUsers = tempUsers;
    }
  }

  beforeDestroy(): void {
    window.removeEventListener("focus", this.loadMissedMessages);
  }

  async loadMissedMessages(): Promise<void> {
    if (this.isMobile) {
      await this.$store.dispatch(actionTypes.FETCH_MESSAGES, +this.$route.params.id);
    }
  }

  clearConversation() {
    this.$router.push("/inbox");
    this.$store.dispatch(actionTypes.CLEAR_CURRENT_CONVERSATION);
  }

  scrollToDummy(): void {
    const dummy = document.querySelector(".dummy");
    if (dummy) {
      this.$nextTick(() => {
        dummy.scrollIntoView();
        this.isLoaded = true;
      });
    }
  }

  changeMessageBoxHeight(event: Event): void {
    if (!event) {
      return;
    }

    const height = (event.target as Window).innerHeight;
    this.setMessageBoxHeight(height);
  }

  setMessageBoxHeight(height: number): void {
    const messageBox = document.querySelector(".message-box");
    if (!messageBox) {
      return;
    }

    (messageBox as HTMLElement).style.height = `${height}px`;
    (messageBox as HTMLElement).style.maxHeight = `${height}px`;
  }

  get initials(): string {
    if (!this.currentConversation) {
      return "";
    }

    return Helpers.getInitials(
      this.currentConversation.latestMessage!.contact ||
        this.currentConversation.latestMessage!.agentAccount
    );
  }

  showEditDrawer(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, {
      contactId: this.currentContact.id,
      insideConversation: true
    });
  }
}
